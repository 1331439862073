<script setup>
import { ref } from 'vue'
import ImageOption from '../atoms/ImageOption.vue'
import ContinueButton from '../molecules/ContinueButton.vue'

const { title, options } = defineProps(['title', 'text', 'options'])

const selectedOptions = ref({})
</script>

<template>
  <div class="image-select-container">
    <p class="heading-5">{{ title }}</p>
    <div class="options-container mt-16">
      <ImageOption
        v-for="(option, index) in options"
        :key="option.name"
        :img="option.img"
        :name="option.name"
        :isSelected="selectedOptions[index] == true"
        @click="
          () => {
            selectedOptions[index] = !selectedOptions[index]
          }
        "
      />
    </div>
    <ContinueButton
      @click="
        $emit(
          'selection',
          Object.keys(selectedOptions).filter((item) => selectedOptions[item] == true)
        )
      "
    />
  </div>
</template>

<style scoped>
.image-select-container {
  /* overflow: none; */
  display: flex;
  flex-direction: column;
}
.options-container {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  justify-content: center;
  /* margin-bottom: 30px; */
}

@media screen and (max-width: 850px) {
  .heading-5 {
    font-size: 21px;
  }
}

</style>
