<script setup>
const { infoText, questions, options, style, currentQuestionIndex } = defineProps([
  'infoText',
  'questions',
  'options',
  'style',
  'currentQuestionIndex'
])

const emit = defineEmits(['progress'])
const handleSelection = (value) => {
  emit('progress', value)
}
</script>

<template>
  <Transition name="fade" mode="out-in" appear>
    <Teleport to="body">
      <div class="modal-overlay">
        <slot></slot>
        <div class="modal-content" :style="style">
          <p class="sub-h-sm" style="opacity: 0.5">{{ infoText.toUpperCase() }}</p>
          <Transition name="fade" mode="out-in">
            <div :key="currentQuestionIndex">
              <p class="heading-5 mt-12 center-text">
                {{ questions[currentQuestionIndex].title }}
              </p>
            </div>
          </Transition>

          <div class="buttons">
            <button
              v-for="(answer, index) in options"
              :key="index"
              class="answer button"
              @click="handleSelection(answer)"
            >
              <p class="label-md">{{ answer }}</p>
            </button>
          </div>
        </div>
      </div>
    </Teleport>
  </Transition>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(31, 32, 42, 0.3);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 480px;
  padding: 24px;

  border-radius: 24px;
  background: var(--neutrals-0, #fff);
}

.sub-h-sm {
  color: var(--neutrals-500, #fff);
  line-height: 16px;
  letter-spacing: 0.48px;
}

.buttons {
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 16px;
}

.button {
  display: flex;
  height: 60px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid var(--neutrals-200, #e2e4e9);
  background: var(--neutrals-0, #fff);

  color: var(--label-primary, #000);
  text-align: center;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-Lighter, #edf1ff);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 850px) {
  .modal-content {
    margin-top: 100px;
  }
}
</style>
