<script setup>
import { computed } from 'vue'

const { img, title, options } = defineProps(['img', 'title', 'options'])
const imageSrc = computed(() => {
  return new URL(`../../assets/images/${img}.png`, import.meta.url).href
})
</script>

<template>
  <div class="container">
    <p class="heading-5">{{ title }}</p>
    <div class="img-container">
      <img :src="imageSrc" alt="Image" />
    </div>
    <div class="like-buttons">
      <button class="btn" @click="$emit('selection', 0)">{{ options[0] }}</button>
      <button class="btn" @click="$emit('selection', 1)">{{ options[1] }}</button>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 428px;
  height: 100%;
  justify-content: center;
}

.like-buttons {
  z-index: 100;
  display: flex;
  gap: 8px;
}

.img-container {
  width: 280px;
  height: 420px;
}

img {
  width: 100%;
}
.btn {
  padding: 32px;
}

@media screen and (max-width: 850px) {
  .img-container {
    width: 200px;
    height: 300px;
  }
  .like-buttons {
    position: fixed;
    bottom: 80px;
  }
}
</style>
