import { requestWithIdToken } from '@/helpers/requestWithIdToken'

export const createPaddleTransaction = async data => {
  try {
    const response = await requestWithIdToken({
      path: '/paddle/create-transaction',
      method: 'POST',
      data,
    })
    const responseData = response.data
    if (responseData.success && responseData.data.id) {
      return responseData.data.id
    } else if (!responseData.success) {
      throw responseData.error
    }
  } catch (error) {
    console.log(error)
  }
}

export const getTransactionDetails = async trxId => {
  try {
    const response = await requestWithIdToken({
      path: `/paddle/get-transaction/${trxId}`,
      method: 'GET',
    })
    const responseData = response.data
    if (responseData.success && responseData.data?.data) {
      return responseData.data.data
    } else if (!responseData.success) {
      throw responseData.error
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}
