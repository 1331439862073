<script setup>
import { computed } from 'vue'
import ContinueButton from '../molecules/ContinueButton.vue';

const { title, text, imgName } = defineProps(['title', 'text', 'imgName'])

const imageSrc = computed(() => {
  return new URL(`../../assets/images/${imgName}.png`, import.meta.url).href
})
</script>

<template>
  <div class="container">
    <div class="img-container">
      <img :src="imageSrc" :alt="imgName" />
    </div>
    <p class="heading-5">{{ title }}</p>
    <p class="p-md">{{ text }}</p>
    <ContinueButton @click="$emit('cta')"/>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0px 16px;
  max-width: 428px;
}

.img-container {
  height: 250px;
}

img {
  width: auto;
  height: 100%;
  max-width: 400px;
}

button {
  display: inline-block;
}

.heading-5 {
  margin-top: 16px;
  margin-bottom: 16px;
}

@media screen and (max-width: 850px) {
  .img-container {
    height: 200px;
  }
}
</style>
