<script setup>
import { ref } from 'vue'
import FeedbackCard from '../atoms/FeedbackCard.vue'
import ContinueButton from '../molecules/ContinueButton.vue'
import CrossIcon from '@/assets/icons/cross.png'
import CheckIcon from '@/assets/icons/check.png'

const { title, options, propSelectedIndex, isWithFeedback } = defineProps([
  'title',
  'options',
  'selectedIndex',
  'isWithFeedback'
])

const emit = defineEmits(['selection'])

const onSelect = (index) => {
  selectedIndex.value = index
  if (!isWithFeedback) {
    emit('selection', index)
  }
}

const selectedIndex = ref(propSelectedIndex)
</script>
<template>
  <div class="select-container">
    <p class="heading-5">{{ title }}</p>
    <div style="flex: 1;"></div>
    <FeedbackCard
      class="mt-30"
      v-if="isWithFeedback && selectedIndex !== undefined && options[selectedIndex].feedback"
      :title="options[selectedIndex].feedback.title"
      :text="options[selectedIndex].feedback.text"
    />
    <div class="options-container mt-16">
      <button
        @click="onSelect(0)"
        :class="`btn heading-5 mt-8 ${selectedIndex == 0 ? 'selected' : ''}`">
        <img :src="CrossIcon" :alt="'Cross icon'" class="icon" />
      </button>
      <button
        @click="onSelect(1)"
        :class="`btn heading-5 emoji mt-8 ${selectedIndex == 1 ? 'selected' : ''}`">
        <span>🤔</span>
      </button>
      <button
        @click="onSelect(2)"
        :class="`btn heading-5 mt-8 ${selectedIndex == 2 ? 'selected' : ''}`">
        <img :src="CheckIcon" :alt="'Check icon'" class="icon" />
      </button>
    </div>
    <ContinueButton
      v-if="isWithFeedback"
      :disabled="selectedIndex === undefined"
      @click="$emit('selection', selectedIndex)"
    />
  </div>
</template>
<style scoped>
.select-container {
  display: flex;
  flex-direction: column;
}

.options-container {
  display: flex;
  flex-direction: row;
  padding-top: 40px ;
  gap: 8px;
}
.options-container button {
  flex-grow: 1;
}

.icon {
  width: 28px;
  height: 28px;
}

.emoji {
  font-size: 28px;
}

@media screen and (max-width: 850px) {
  .select-container {
    min-height: calc(100vh - 360px);
  }
  .options-container {
    padding-top: 0;
  }
}
</style>
