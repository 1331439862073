import { discount } from '@/config/products'

export const steps = [
  {
    name: 'age_selection',
    component: 'AgeSelection',
    props: {
      options: ['18-24', '25-34', '35-44', '45+'],
      title: 'Stand out as the most interesting person in the room.'
    }
  },
  {
    name: 'testimonial_selection',
    component: 'TestimonialSection',
    props: {
      imgName: 'book_reading',
      title: 'Sparkle is a tool for acquiring and absorbing as much information as possible',
      text: 'We’ll help you with self-growth. First, let’s dive into your personality and tailor your personal plan.'
    }
  },
  {
    name: 'gender_selection',
    component: 'SelectOneList',
    props: {
      options:
        [
          { sendGTag: true, icon: '👨🏻', text: 'Male' },
          { sendGTag: true, icon: '👩🏻', text: ' Female' },
          { sendGTag: true, icon: '🧑🏻‍🦰', text: ' Non-Binary' },
        ],
      title: 'Select your gender identity'
    },
    dynamicResponse: false,
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'book_reading',
        title: 'Yes, it’s wonderful to have you!',
        text: 'Let’s start your journey of self-growth. We’ll begin by learning about you to craft a customized plan.'
      }
    },
    section: 'Introduction'
  },
  {
    name: 'areas_selection',
    component: 'MultiSelectCard',
    props: {
      options: [
        'Emotions',
        'Habits',
        'Motivation',
        'Mindset',
        'Creativity',
        'Nutrition',
        'Exercise',
        'Self-confidence',
        'Empathy',
        'Self-care',
        'Personal Finance',
        'Love & relationships',
      ],
      title: 'In which areas do you want to grow?',
      text: 'Your experience won’t be limited by the choices'
    },
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'joy',
        title: 'Capture essential insights in minutes, not in hours!',
        text: "It takes 15 minutes to finish any book with Sparkle. This frees you up to spend your valuable time acting on what you’ve learned."
      }
    },
    section: 'Introduction'
  },
  {
    name: 'people_selection',
    component: 'MultiSelectWithImagesCard',
    props: {
      options: [
        { img: 'branson', name: 'Richard Branson' },
        { img: 'williams', name: 'Serena Williams' },
        { img: 'degeneres', name: 'Ellen DeGeneres' },
        { img: 'james', name: 'LeBron James' },
        { img: 'buffet', name: 'Warren Buffet' },
        { img: 'jobs', name: 'Steve Jobs' },
        { img: 'hart', name: 'Kevin Hart' },
        { img: 'zendaya', name: 'Zendaya' },
        { img: 'robbins', name: 'Mel Robbins' }
      ],
      title: 'Who inspires you with their success and personality the most?'
    },
    section: 'Introduction'
  },
  {
    name: 'personal_view',
    component: 'SelectOneList',
    props: {
      options: [
        {
          icon: '🔭',
          text: 'Focus on big picture',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'book_with_glasses',
              title: 'Big-picture thinking aids problem-solving.',
              text: 'It’s all about balance: there isn’t a single right perspective and details complete the bigger picture. We’ll craft your program to guide you toward combining big picture and details, helping you excel in every area.'
            }
          }
        },
        {
          icon: '🔬',
          text: 'Dive into details',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'book_with_magnifier',
              title: 'Detail-oriented thinking aids flawless task completion.',
              text: 'Indeed, there’s no right or wrong way, and seeing the big picture is just as important. So we’ll consider this in creating your plan to help you improve your ability to see both the scope and details clearly!'
            }
          }
        }
      ],
      title: 'Generally, do you focus on big picture or dive into details?'
    },
    dynamicResponse: true,
    section: 'Introduction'
  },
  {
    name: 'know_what_you_want',
    component: 'SelectOneRow',
    props: {
      title: 'Are you always certain about what you want?',
      options: [
        {
          text: '❌',
          feedback: {
            title: 'We hear you!',
            text: 'Your selected growth points will guide us in tailoring the most fitting plan for you.'
          }
        },
        {
          text: '🤔',
          feedback: {
            title: 'We got you!',
            text: 'We’ll focus on the improvement points you’ve mentioned to craft a plan that truly aligns with your goals.'
          }
        },
        {
          text: '✅',
          feedback: {
            title: 'Were you aware people spend more than 4 hours on their phones every day?',
            text: "If you put just 15 mins aside daily for using Sparkle you'll grow your knowledge and reach your goals."
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Introduction'
  },
  {
    name: 'doubt_step',
    component: 'SelectOneRow',
    props: {
      title: 'When I make mistakes, I often find myself doubting my abilities.',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'glad_with_book',
              title: 'We’re pleased to hear that!',
              text: 'Mistakes are chances to grow, not setbacks. With your growth plan, you’ll learn how to turn them into valuable lessons for the future.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'question_on_book',
              title:
                'People learn by making mistakes. It is common to doubt your abilities when you make one.',
              text: 'Your personalized growth plan will be adressing this issue too. So don’t worry, we’re here to help!'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'question_on_book',
              title:
                'People learn by making mistakes. It is common to doubt your abilities when you make one.',
              text: 'Your personalized growth plan will be adressing this issue too. So don’t worry, we’re here to help!'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Introduction'
  },
  {
    name: 'describe_self',
    component: 'SelectOneList',
    props: {
      title: 'Which of the following describes you better?',
      options: [
        {
          icon: '🧠',
          text: 'A leader',
          feedback: {
            title: 'Leader!',
            text: 'The role of a leader can be quite demanding. By spending just 15 minutes a day, you’ll efficiently learn how to enhance your leadership skills.'
          }
        },
        {
          icon: '🤝',
          text: 'A supporter',
          feedback: {
            title: 'To grow, you have to be aware of yourself.',
            text: 'Some of the best individual contributors and team players are supporters. Yet, we’ll help you grow even further by identifying your strong sides and areas to growth.'
          }
        },
        {
          icon: '🧩',
          text: 'A little bit of both',
          feedback: {
            title: "Classic roles don't define you!",
            text: 'The personalized growth plan and our extensive collection of books will help you improve in all aspects.'
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Introduction'
  },
  {
    name: 'decision_making',
    component: 'SelectOneRow',
    props: {
      title: 'It is hard for me to decide quickly.',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'victory_view',
              title: 'Great job!',
              text: "Your determination and promptness is the key to growth.",
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'embrace_view',
              title: 'It is normal to hesitate while making decisions, however it can hold you back sometimes.',
              text: 'We’re here to help you address this!'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'embrace_view',
              title: 'It is normal to hesitate while making decisions, however it can hold you back sometimes.',
              text: 'We’re here to help you address this!'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Introduction'
  },
  {
    name: 'face_challenges',
    component: 'SelectOneRow',
    props: {
      title: 'I often feel like I lack the inner strength to face challenges.',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'motivational_view',
              title: 'Motivation is the key to your success!',
              text: "You'll be twice as motivated when your goals are clear. Use your growth plan to keep them in sight, even during tough times."
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'motivational_view',
              title: 'Motivation is essential for success!',
              text: 'Your motivation will be much higher when you set clear goals. Even when challenges arise, your growth plan will help you pursue your goals.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'motivational_view',
              title: 'Motivation is essential for success!',
              text: 'Your motivation will be much higher when you set clear goals. Even when challenges arise, your growth plan will help you pursue your goals.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Introduction'
  },
  {
    name: 'extrovert_introvert',
    component: 'SelectOneList',
    props: {
      options: [
        {
          icon: '😁',
          text: 'Extrovert',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'extrovert',
              title: 'Cheers to the extroverts!',
              text: 'You’re on a dream team with Frida Kahlo, Maya Angelou, and Michelle Obama. To harness your full potential, you need a strategy as unique as you are. We’ll use what you share to create a growth plan tailored just for you.'
            }
          }
        },
        {
          icon: '🧐',
          text: 'Introvert',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'introvert',
              title: 'Cheers to the introverts!',
              text: 'You’re on a dream team with Coco Chanel, Amelia Earhart, and Marie Curie. To harness your full potential, you need a strategy as unique as you are. We’ll use what you share to create a growth plan tailored just for you.'
            }
          }
        },
        {
          icon: '🙂',
          text: 'Both',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'ambivert',
              title: 'Cheers to the ambivert team!',
              text: 'Ambiverts, who adapt between extroversion and introversion depending on the situation, need a special approach to find happiness.'
            }
          }
        }
      ],
      title: 'How would you describe yourself?'
    },
    dynamicResponse: true,
    section: 'Character'
  },
  {
    name: 'regular_workouts',
    component: 'SelectOneRow',
    props: {
      title: 'In your opinion, does working out regularly improve work performance?',
      options: [
        {
          text: '❌',
          feedback: {
            title: 'Actually it does wonders!',
            text: 'A workout routine is essential to maintain energy and reach career goals. We’ll tailor your growth plan to help you find the right balance to stay energized.'
          }
        },
        {
          text: '🤔',
          feedback: {
            title: 'You can find out more with your personalized growth plan.',
            text: 'People with workout routines experience improvements on memory, concentration and stress levels.'
          }
        },
        {
          text: '✅',
          feedback: {
            title: 'We feel the same way!',
            text: 'Maintaining a healthy work-life balance, including regular workouts, greatly enhances your ability to stay productive.'
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Character'
  },
  {
    name: 'friendly_push',
    component: 'SelectOneRow',
    props: {
      title: 'Do you need extra motivation from time to time?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'books_on_table',
              title: 'A supportive nudge might not work for everyone, and that’s completely fine.',
              text: 'While creating your growth plan, we will keep this in mind.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'bell_on_books',
              title: 'Sometimes a little encouragement can make wonders.',
              text: 'While creating your growth plan, we will keep this in mind.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'bell_on_books',
              title: 'Sometimes a little encouragement can make wonders.',
              text: 'While creating your growth plan, we will keep this in mind.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Character'
  },
  {
    name: 'time_management',
    component: 'SelectOneRow',
    props: {
      title: 'In terms of time management skills, do you feel like you need to improve?',
      options: [
        {
          text: '❌',
          feedback: {
            title: "That’s good to hear!",
            text: 'If you feel the need someday, we can alter your growth plan to adress this as well.'
          }
        },
        {
          text: '🤔',
          feedback: {
            title: 'Struggling to keep up with time? Many feel the same way.',
            text: 'We can help you improve your time management in a way that fits you.'
          }
        },
        {
          text: '✅',
          feedback: {
            title: 'We admire your drive!',
            text: 'Effective time management is key to reaching your life goals, and we’re here to support you in mastering it.'
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Character'
  },
  {
    name: 'boundaries_in_relationships',
    component: 'SelectOneRow',
    props: {
      title: 'Do you struggle to maintain boundaries in relationships?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'glad_with_book',
              title: 'That’s great to hear!',
              text: 'Maintaining boundaries is very important for healthy relationships.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'question_on_book',
              title: 'Losing boundaries can be painful. They play a crucial role for healthy relationships.',
              text: 'The plan we’ll prepare will guide you to improve this. Don’t worry!'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'book_reading_girl',
              title: 'Losing boundaries can be painful. They play a crucial role for healthy relationships.',
              text: 'The plan we’ll prepare will guide you to improve this. Don’t worry!'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Character'
  },
  {
    name: 'sex_life',
    component: 'SelectOneRow',
    props: {
      title: 'Are you pleased with your sex life?',
      options: [{ text: '❌' }, { text: '🤔' }, { text: '✅' }]
    },
    dynamicResponse: false,
    section: 'Character'
  },
  {
    name: 'body_and_desires',
    component: 'SelectOneRow',
    props: {
      title: 'Are you looking for a deeper understanding of your body and desires?',
      options: [{ text: '❌' }, { text: '🤔' }, { text: '✅' }]
    },
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'girl_looking_into_mirror',
        title: ' Self-reflection is crucial for self-growth!',
        text: 'We’ll assist you in exploring your thoughts, feelings, and actions more deeply to guide you towards becoming a better version of yourself.'
      }
    },
    dynamicResponse: false,
    section: 'Character'
  },
  {
    name: 'when_succeed_in_something',
    component: 'SelectOneList',
    props: {
      options: [
        {
          icon: '🧐',
          text: 'Focus on what could be improved',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'woman_with_coffee',
              title:
                'It’s great to look for improvement even further however do not forget to give yourself a round of applause sometimes.',
              text: 'Staying motivated on your path to success means celebrating wins and finding areas to grow—your growth plan will guide you in both.'
            }
          }
        },
        {
          icon: '🥳',
          text: 'Celebrate the success',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'man_celebrating',
              title: 'Staying positive boosts motivation significantly.',
              text: 'We will help you to continue this positive attitude and we will also identify areas to improve for you.'
            }
          }
        }
      ],
      title: 'What do you prefer after achieving success?'
    },
    dynamicResponse: true,
    section: 'Tendencies'
  },
  {
    name: 'enjoy_your_job',
    component: 'SelectOneRow',
    props: {
      title: 'Do you enjoy your job?',
      options: [
        {
          text: '❌',
          feedback: {
            title: 'You’re not alone!',
            text: 'Sparkle has helped millions find a fast way to improve their lives in minutes a day. Don’t worry, we got you!'
          }
        },
        {
          text: '🤔',
          feedback: {
            title: 'Thinking about making a change?',
            text: 'While transitions can be tough, having a plan makes them manageable. We’ll work with you to target your growth areas and achieve meaningful change.'
          }
        },
        {
          text: '✅',
          feedback: {
            title: 'Love what you do? Fantastic!',
            text: 'Millions of Sparkle users are transforming their lives with just a few minutes a day—join them and see the difference for yourself.'
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Tendencies'
  },
  {
    name: 'work_life_balance',
    component: 'SelectOneRow',
    props: {
      title: 'Are you satisfied with your work-life balance?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'sleeping',
              title:
                'A solid work-life balance plays a key role in staying sharp and reaching your goals.',
              text: 'Achieving balance across all life domains will be easier with your growth plan, keeping you both vibrant and content.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: 'It’s amazing to hear that!',
              text: 'Keeping a healthy work-life balance helps you stay effective and is one of the building blocks of success.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: 'It’s amazing to hear that!',
              text: 'Keeping a healthy work-life balance helps you stay effective and is one of the building blocks of success.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Tendencies'
  },
  {
    name: 'professional_potential',
    component: 'SelectOneRow',
    props: {
      title: 'Do you believe you can achive much more in professional life?',
      options: [{ text: '❌' }, { text: '🤔' }, { text: '✅' }]
    },
    dynamicResponse: false,
    section: 'Tendencies'
  },
  {
    name: 'financial_situation',
    component: 'SelectOneRow',
    props: {
      title: 'Are you satisfied with your financial situation?',
      options: [
        {
          text: '❌',
          feedback: {
            title: 'Thank you for your openness!',
            text: 'After the quiz, you’ll gain access to insights from top bestsellers on financial management, entrepreneurship, and handling money.'
          }
        },
        {
          text: '🤔',
          feedback: {
            title: 'Wondering how to take the first step toward better finances?',
            text: 'A remarkable 94% of our users discovered actionable financial insights on Sparkle that significantly improved their financial expertise.'
          }
        },
        {
          text: '✅',
          feedback: {
            title: 'Glad to hear that!',
            text: "Based on your responses, we'll concentrate on other key areas in your growth plan."
          }
        }
      ],
      isWithFeedback: true
    },
    section: 'Tendencies'
  },
  {
    name: 'good_enough',
    component: 'SelectOneRow',
    props: {
      title: 'Do you frequently worry that you’re not good enough?',
      options: [
        {
          text: '❌',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'girl_on_a_desk',
              title: 'We’re impressed by your confidence!',
              text: 'It’s crucial for success, and we’ll help you elevate it to even greater heights.'
            }
          }
        },
        {
          text: '🤔',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: 'It’s okay to feel less than perfect.',
              text: 'Overcoming this challenge will bring you one step closer to success. We’ll include tailored confidence insights in your plan to support your journey.'
            }
          }
        },
        {
          text: '✅',
          responsePage: {
            component: 'InformationCard',
            props: {
              imgName: 'meditating',
              title: 'It’s okay to feel less than perfect.',
              text: 'Overcoming this challenge will bring you one step closer to success. We’ll include tailored confidence insights in your plan to support your journey.'
            }
          }
        }
      ]
    },
    dynamicResponse: true,
    section: 'Tendencies'
  },
  {
    name: 'compare_yourself',
    component: 'SelectOneRow',
    props: {
      title: 'Do you tend to compare yourself with others frequently?',
      options: [{ text: '❌' }, { text: '🤔' }, { text: '✅' }]
    },
    dynamicResponse: false,
    responsePage: {
      component: 'InformationCard',
      props: {
        imgName: 'bookshelf',
        title: 'Last month alone, more than 280,300 book summaries were read by our users!',
        text: 'Be a part of our learning community and find out ways to prioritize self-growth, no matter how busy you are.'
      }
    },
    section: 'Tendencies'
  },
  {
    name: 'image_the_art_of_work',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'the_art_of_work',
      title: 'Do you find this book interesting?'
    }
  },
  {
    name: 'zero_to_one',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_1',
      title: 'Do you find this book interesting?'
    }
  },
  {
    name: 'art_of_war',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_2',
      title: 'Do you find this book interesting?'
    }
  },
  {
    name: 'atomic_habits',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_3',
      title: 'Do you find this book interesting?'
    }
  },
  {
    name: 'mans_search_for_meaning',
    component: 'ImageLike',
    props: {
      options: ['👎', '👍'],
      img: 'example_book_4',
      title: 'Do you find this book interesting?'
    }
  },
  {
    name: 'social_proof_first',
    component: 'SocialProof'
  },
  {
    name: 'categories',
    component: 'MultiSelectList',
    props: {
      title: 'What categories do you like?',
      subtitle: 'Choose up to 3 categories for more precise personalization',
      options: [
        {
          name: 'Business & Career',
          id: '76mWYbNXqjPEnUY30C0q',
          iconComponentName: 'IconCat1'
        },
        {
          name: 'Entrepreneurship',
          id: '50eVUFF676ctoDxG7ine',
          iconComponentName: 'IconCat2'
        },
        {
          name: 'Money & Investment',
          id: '3NjiNbZ7fbNRCGd8IQul',
          iconComponentName: 'IconCat3'
        },
        {
          id: 'f3ByDrIl3OM8OzGNsCoy',
          name: 'Leadership',
          iconComponentName: 'IconCat4'
        },
        {
          name: 'Productivity',
          id: 'HMKfoK2ll7WjLWWYd1XU',
          iconComponentName: 'IconCat5'
        },
        {
          name: 'Communication Skills',
          id: 'eMD6vHja2BdjD6goyFBL',
          iconComponentName: 'IconCat6'
        },
        {
          name: 'Technology & Future',
          id: 'jUK8a5VvqF5YtMuKPWSZ',
          iconComponentName: 'IconCat7'
        },
        {
          name: 'Self Growth',
          id: 'TeGoKmsCMt1gAoYTAqSQ',
          iconComponentName: 'IconCat8'
        },
        {
          name: 'Creativity',
          id: 'Me980EskZKrY8eHbUchf',
          iconComponentName: 'IconCat9'
        },
        {
          name: 'Happiness',
          id: 'LIV3AQ5seK0xa5w9gB00',
          iconComponentName: 'IconCat10'
        },
        {
          name: 'Love & Sex',
          id: 'eco4732JGYzc59PKbCUh',
          iconComponentName: 'IconCat11'
        },
        {
          name: 'Psychology',
          id: 'CuYDjrntJ8izaRRF3C8Y',
          iconComponentName: 'IconCat12'
        },
        {
          name: 'Spirituality',
          id: '8c3GkS9XLYzkJRlrWjF0',
          iconComponentName: 'IconCat13'
        },
        {
          name: 'Family',
          id: '2f5bq7kllLPimQw780Nh',
          iconComponentName: 'IconCat14'
        },
        {
          name: 'Society & Culture',
          id: 'vHwjNkCUSYN28W6fppGK',
          iconComponentName: 'IconCat15'
        },
        {
          name: 'Nature & Environment',
          id: 'R79qvCmbd662BlnW6HTM',
          iconComponentName: 'IconCat16'
        },
        {
          name: 'Health & Nutrition',
          id: 'EmihPGxqTDUqd1STto7b',
          iconComponentName: 'IconCat17'
        },
        {
          name: 'Sports & Fitness',
          id: 'VvJDH3lgxJRfd2M57glu',
          iconComponentName: 'IconCat18'
        }
      ],
      maxSelections: 3
    }
  },
  {
    name: 'self_development_daily',
    component: 'SelectOneList',
    props: {
      options: [
        { icon: '🤙', text: 'Easy', subtext: '5 min/day' },
        { icon: '👌', text: 'Common', subtext: '10 min/day' },
        { icon: '🤘', text: 'Serious', subtext: '15 min/day' },
        { icon: '💪', text: 'Intensive', subtext: '20+ min/day' }
      ],
      title: 'Set your goal for timing',
      text: 'Choose how much time you’ll dedicate to self-development each day.'
    }
  },
  {
    name: 'loading_pop_ups',
    component: 'LoadingPopUps',
    props: {
      title: 'Creating your personal experience...',
      popUpHeader: 'To make progress, please clarify',
      imgName: 'open_book',
      quote: {
        text: '"<span class="primary-color">Sparkle</span> is a tool for acquiring and absorbing as much information as possible”',
        author: 'Fig Nelson',
        imgName: 'fig_nelson_person'
      },
      items: [
        { icon: '🎯', title: 'Settings Goals' },
        { icon: '📈', title: 'Defining Growth Areas' },
        { icon: '📚', title: 'Searching Contents' },
        { icon: '⚔️', title: 'Adjust Challenges' }
      ],
      hasModal: true,
      options: ['No', 'Yes'],
      questions: [
        {
          title: 'Do you engage in self reflection?',
          qID: 'engage_self_reflection'
        },
        {
          title: 'Do you feel like giving up when things get tough?',
          qID: 'give_up_when_tough'
        },
        {
          title: 'Do you enjoy learning through listening?',
          qID: 'learn_by_listening'
        },
        {
          title: 'Would you say you’re likely to finish what you start?',
          qID: 'inclined_to_see'
        }
      ]
    }
  },
  {
    name: 'email_form',
    component: 'EmailForm'
  },
  {
    name: 'plan_page',
    component: 'PlanPage'
  },
  {
    name: 'social_proof_second',
    component: 'SocialProof2'
  }
]
export const QUIZ_ID = 'onboarding-1'

export const onboardings = {
  'onboarding-1': {
    steps: steps,
    discountId: discount,
    discountRate: 0.6,
    currency: 'USD'
  }
}
