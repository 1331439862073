<script setup>

</script>

<template>
    <div class="p-xs container">
      <p class="legal-links lighter">
        <a href="/policies/Terms.html" target="_blank" class="action-text">Terms & Conditions</a>
        <span>&bull;</span>
        <a href="/policies/Privacy.html" target="_blank" class="action-text">Privacy Policy</a>
      </p>
      <p class="company-name lighter">
        2022 Codespace
      </p>
    </div>
</template>

<style scoped>
.container {
  background-color: var(--background);
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.legal-links {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.company-name {
  display: flex;
  justify-content: center;
}

.lighter {
  opacity: 0.5;
}

</style>