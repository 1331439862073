<script setup>
import CustomButtonSpinner from '@/components/atoms/CustomButtonSpinner.vue'
import MailIcon from '@/assets/icons/mail.svg'
import { computed, ref, watch, onMounted } from 'vue'
import { sendGTagEvent } from '@/helpers/gtag'
import { sendEvent } from '@/helpers/cerebro'
import { steps } from '@/constants/onboarding.js'

const email = ref('')
const emailPermission = ref(false)
const terms = ref(false)
const isEmailValid = ref(false)

const isDisabled = computed(() => {
  if (isEmailValid.value && terms.value) {
    return false
  } else {
    return true
  }
})
const emit = defineEmits(['email-input'])
const props = defineProps({
  isLoading: Boolean
})

const emitEmailInput = () => {
  if (!isDisabled.value && !props.isLoading) {
    emit('email-input', { email: email.value, emailPermission: emailPermission.value })
  }
}

watch(email, (newVal) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (emailRegex.test(newVal)) {
    isEmailValid.value = true
  } else {
    isEmailValid.value = false
  }
})

onMounted(() => {
  sendGTagEvent({ event: 'email_screen_displayed' })

  const emailFormIndex = steps.findIndex((step) => step.name === 'email_form')
  sendEvent({
    eventName: 'email_screen_displayed',
    page_name: 'email_form',
    page_number: emailFormIndex || steps.length - 3
  })
})
</script>

<template>
  <div class="container">
    <p class="heading-5">Achieve your goals with <span class="primary-color">Sparkle!</span></p>
    <p>Enter your email to create a personal account</p>
    <div class="email-form">
      <label for="email" class="label-sm">Email Address</label>
      <div class="input-container mt-4">
        <MailIcon class="input-icon" />
        <input type="email" autocomplete="email" id="email-input" placeholder="user@mail.com" class="p-md email-input" v-model="email" />
      </div>
    </div>
    <div class="checkboxes">
      <div class="checkbox-container">
        <input type="checkbox" class="checkbox-input" id="updates" v-model="emailPermission" />
        <label for="updates" class="p-xs checkbox-label"
          >I agree to receive the latest Sparkle news and updates via email.</label
        >
      </div>
      <div class="checkbox-container">
        <input type="checkbox" class="checkbox-input" id="terms" v-model="terms" />
        <label for="terms" class="p-xs checkbox-label">
          I accept the Sparkle
          <a class="action-text primary-color" href="/policies/Terms.html" target="_blank"
            >Terms & Conditions</a
          >
          and
          <a class="action-text primary-color" href="/policies/Privacy.html" target="_blank">
            Privacy Policy</a
          >
        </label>
      </div>
    </div>
    <button
      class="btn btn-primary"
      :class="[{ isLoading: props.isLoading }]"
      :disabled="isDisabled"
      @click="emitEmailInput"
    >
      Continue
      <Transition>
        <CustomButtonSpinner v-show="props.isLoading" />
      </Transition>
    </button>
    <div class="social-proof">
      <p class="heading-5 primary-color">Join over 1M+ people</p>
      <p class="p-md">
        Become part of a growing worldwide community and achieve your goals with us
      </p>
      <img src="@/assets/icons/five_stars.png" alt="five-stars" width="120px" />
      <div class="other-people p-md">
        <img src="@/assets/images/other_people.png" alt="person" width="92px" />
        from users
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 60px;
  padding: 16px;
  max-width: 428px;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.email-input {
  padding-left: 52px;
  height: 60px;
  border: 1px solid var(--neutrals-200);
  width: 100%;
}

.checkbox-container {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
}

.checkbox-label {
  cursor: pointer;
  color: var(--neutrals-500);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  text-align: left;
  gap: 4px;
}

.checkbox-label > * {
  white-space: nowrap;
}

.checkbox-input {
  width: auto;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
  appearance: none;
  position: relative;
}

.checkbox-input:checked {
  background-color: var(--primary-base);
}

.checkbox-input:checked:before {
  content: '✓';
  color: #fff;
  left: 5px;
  top: 1px;
  position: absolute;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.email-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.other-people {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  color: #737373;
}

.social-proof {
  background: #fff;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border: 1px solid var(--primary-light);
  border-radius: 16px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.isLoading {
  animation: slideIn 0.6s forwards;
}

@keyframes slideIn {
  0% {
    gap: 0px;
  }
  100% {
    gap: 20px;
  }
}
</style>
