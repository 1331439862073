<template>
  <button class="btn btn-primary label-md cnt-btn mt-16" :style="{ bottom: `${bottomOffset}px` }">
    Continue
  </button>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const minOffset = 45;
const maxOffset = 75;
const bottomOffset = ref(maxOffset);

const updateBottomOffset = () => {
  const bottomPosition = document.documentElement.scrollHeight;
  const isScrollable = bottomPosition > document.documentElement.clientHeight;
  if (isScrollable) {
    const scrollableHeight = bottomPosition - window.innerHeight;
    const scrolledAmount = window.scrollY;
    const remainingScroll = scrollableHeight - scrolledAmount;
    bottomOffset.value = remainingScroll >= minOffset ? minOffset : Math.max(minOffset, maxOffset - remainingScroll);
  }
};

onMounted(() => {
  window.addEventListener('scroll', updateBottomOffset);
  window.addEventListener('resize', updateBottomOffset);
  updateBottomOffset();
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateBottomOffset);
  window.removeEventListener('resize', updateBottomOffset);
});
</script>

<style scoped>

button {
  display: inline-block;
}

.cnt-btn {
  margin-top: 32px;
  padding: 20px 0px;
  border-radius: 16px;
  background: var(--primary-base, #3d2df2);
  width: 100%;
  max-width: 428px;
  height: 60px;
  z-index: 999;
  color: #fff;
  text-align: center;
  position: fixed;
  bottom: 30px;
  display: flex;
}

button:disabled {
  opacity: 1;
  background: var(--neutrals-200);
}

@media screen and (max-width: 850px) {
  .cnt-btn {
    width: 100%;
    max-width: min(428px, calc(100vw - 32px));

    margin: 0 auto;
  }
}

</style>