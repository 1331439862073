export const APP_VERSION = '1.0.0'
export const APP_ID = "com.codespaceapps.aibookweb"
export const SUPPORT_EMAIL = 'support.sparkle@codespaceapps.co'
export const FAQS = [
  {
    question: "Why do I need Sparkle app? ",
    answer: "Sparkle offers 15-minute summaries of the world’s top nonfiction books, reimagined as concise reads packed with key insights and practical tips. With Sparkle, you can expand your knowledge daily, read more efficiently, and uncover the books that truly capture your interest."
  },
  {
    question: "How can I access to the app?",
    answer: "Once you enter your email address, you'll receive an email containing a temporary password and download links for the App Store and Google Play. Simply install the app to access your account."
  },
  {
    question: "How can I cancel my subscription?",
    answer: `You can cancel your subscription at any time. To proceed with cancellation, please reach out to us via email. You can cancel your subscription quickly and easily anytime you want. If you would like to cancel your subscription, please contact our friendly support team at ${SUPPORT_EMAIL}.`
  },
]

export const BACKEND_BASE_URL =
  window.location.href.indexOf('localhost') >= 0 ? 'http://localhost:5000' : ''