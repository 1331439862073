<script setup>
import { onMounted, ref, watch } from 'vue'
import { sendEvent } from '@/helpers/cerebro'
import { useUserStore } from '@/store/user'
import { getCerebroId } from '../../helpers/cerebro'
import SecureCheckIcon from '@/assets/icons/secure_check.svg'
import ProductBox from './ProductBox.vue'
import { onboardings, QUIZ_ID } from '@/constants/onboarding.js'
import { createPaddleTransaction } from '@/services/paddleService'
import { getSessionId } from '@/helpers/sessionManager'

const userStore = useUserStore()

const state = ref('idle')
const errorMessage = ref()
const genericErrorMessage = ref()

const props = defineProps([
  'product',
  'isDiscounted',
  'discountId',
  'isMostPopular',
  'clientSecret',
  'subscriptionId',
  'customerId',
  'price',
  'interval',
  'intervalCount',
  'offersFreeTrial',
  'productId',
  'flow',
  'source',
  'popup'
])

const transactionOptions = {
  items: [
    {
      price_id: props.productId,
      quantity: 1,
    },
  ],
  discount_id: props.isDiscounted ? props.discountId : '',
}

watch(
  () => props.isDiscounted,
  (newVal) => {
    if (!newVal)
      Paddle.Checkout.updateCheckout({
        discountId: null
      })
  }
)

watch(
  () => userStore.userData.email,
  (newVal) => {
    if (newVal) {
      Paddle.Checkout.updateCheckout({
        customer: {
          email: newVal
        }
      })
    }
  }
)

onMounted(async () => {
  let transactionId
  try {
    transactionId = await createPaddleTransaction(transactionOptions)
  } catch (error) {
    console.error(error)
  }
  const checkoutOptions = {
    transactionId: transactionId,
    settings: {
      successUrl: `${window.location.origin}/payment-result?success=true&tid=${transactionId}&sessionId=${await getSessionId()}`,
    },
    customer: {
      email: userStore.user.email,
    },
    customData: {
      userId: userStore.user ? userStore.user.uid : null,
      cerebroId: getCerebroId(),
    },
  }
  Paddle.Checkout.open(checkoutOptions)
  sendEvent({
    eventName: 'checkout_displayed',
  })
})

const showErrorMessage = (message) => {
  sendEvent({
    eventName: 'checkout_info_error',
    properties: { error: message }
  })
  emailInput.value.style.borderColor = '#EA4335'
  emailInput.value.style.boxShadow =
    '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #EA4335'
  errorMessage.value.innerText = message || 'An error occurred. Please try again.'
  state.value = 'idle'
}
</script>

<template>
  <div class="payment-area">
    <h1>
      {{ offersFreeTrial ? 'Start 3-day free trial, No payment now!' : 'Checkout' }}
    </h1>
    <p>
      <span class="primary-color">91% of users</span> stay with us after the initial 30-day
      subscription
    </p>
    <ProductBox
      :product="product"
      :isSelected="true"
      :isDiscounted="isDiscounted"
      :isMostPopular="isMostPopular"
      :discountAmount="0.6"
    />
    <div class="checkout-container"></div>
    <div class="p-xs">
      By continuing an account, you agree to our
      <a class="legal-links" href="/policies/Terms.html" target="_blank">Terms of Service</a>
      and
      <a class="legal-links" href="/policies/Privacy.html" target="_blank">Privacy </a> &
      <a class="legal-links" href="/policies/Cookie.html" target="_blank">Cookie Statement </a>.
    </div>

    <div class="payment-methods">
      <div class="secure-badge"><SecureCheckIcon /> &nbsp;Pay safe & secure</div>
      <img src="@/assets/images/payment_methods.png" alt="payment-methods" width="100%" />
    </div>
  </div>
</template>

<style scoped>
.payment-area {
  padding: 40px;
  border: 1px solid #ffffff1a;
  border-radius: 20px;
  width: 100%;
  max-width: 480px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.payment-area > h1 {
  font-size: 24px;
}

.payment-area > h2 {
  font-size: 16px;
  margin-top: 12px;
}

.payment-area > p {
  font-size: 16px;
  margin: 8px 0 24px;
}

#error-message {
  font-size: 16px;
  margin: -11.625px 0 16px;
  color: #ea4335;
}

#generic-error-message {
  font-size: 16px;
  margin-top: 10px;
  color: #ea4335;
}

.spinner {
  height: 19px;
  width: 19px;
}
.p-xs,
.legal-links {
  text-align: center;
  color: var(--neutrals-500);
}

.payment-methods {
  text-align: center;
  padding-bottom: 12px;
}

.payment-methods > img {
  width: 100%;
}

.secure-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--neutrals-000);
  padding: 6px 8px;
  margin: 16px 0px 12px 0px;
  font-size: 14px;
}

@media screen and (max-width: 1280px) {
  .payment-area {
    width: 100%;
    padding: 40px 20px;
  }
}
</style>
