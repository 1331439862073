import { signInWithCustomToken } from 'firebase/auth'
import { isStaging } from '@/helpers/environment'
import { auth } from '@/plugins/firebase'
import { requestWithIdToken } from '@/helpers/requestWithIdToken'

export const changeEmail = async newEmail => {
  try {
    const response = await requestWithIdToken({
      path: '/auth/change-mail',
      method: 'POST',
      data: {
        email: newEmail,
      },
    })
    const responseData = response.data
    if (isStaging) console.log('user request', responseData)
    if (responseData.success) {
      if (isStaging) console.log('User created successfully', responseData)
      await signInWithCustomToken(auth, responseData.customToken)
      if (isStaging) console.log('Signed in successfully', responseData)
    }
    return responseData
  } catch (error) {
    console.log(error)
  }
}

export const getRedirectLink = async trackerName => {
  try {
    const response = await requestWithIdToken({
      path: '/auth/get-redirect-link',
      method: 'POST',
      data: { trackerName },
    })
    const responseData = response.data
    if (responseData.success && responseData.redirectLink) {
      return responseData.redirectLink
    } else if (!responseData.success) {
      throw responseData.error
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}
