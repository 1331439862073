<script setup>
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps([
  'product',
  'selectProduct',
  'isSelected',
  'discountAmount',
  'isMostPopular',
  'isDiscounted'
])

const { product, selectProduct, initiateCheckout, discountAmount, isMostPopular } = props

const getFreeTrial = () => {
  if (product.offersFreeTrial) {
    return `${product.recurring?.trial_period_frequency ?? ''}-${product.recurring?.trial_period_interval ?? ''} Free Trial`
  }
  return ''
}

const unitAmountToPriceText = (unitAmount) => {
  return `$${(unitAmount / 100).toFixed(2)}`
}

const getNickName = () => {
  if (product) return product.nickname
}

const freeTrial = getFreeTrial()

const priceText = unitAmountToPriceText(product.unit_amount)

const discountedPrice = computed(
  () => (product.unit_amount * (props.isDiscounted ? 1 - discountAmount : 1)) / 100
)

const discountedPriceText = computed(() => {
  return unitAmountToPriceText(discountedPrice.value * 100)
})

const calculatePricePerDay = (isDynamic) => {
  const price =
    isDynamic && props.isDiscounted
      ? (product.unit_amount * (1 - discountAmount)) / 100
      : product.unit_amount / 100
  const interval = product.recurring?.interval
  const freq = product.recurring?.interval_count
  const dayCount =
    (interval == 'week' ? 7 : interval == 'month' ? 30 : interval == 'year' ? 365 : 1) * (freq || 1)
  const dailyPrice = price / dayCount
  const dailyPriceText = dailyPrice.toFixed(2)
  if (isDynamic) {
    return { whole: dailyPriceText.split('.')[0], fraction: dailyPriceText.split('.')[1] }
  } else {
    return dailyPriceText
  }
}
</script>

<template>
  <div
    class="product-box"
    :class="{ selected: isSelected, 'most-popular-box': isMostPopular }"
    :onclick="selectProduct"
  >
    <div class="most-popular" v-if="isMostPopular">MOST POPULAR</div>
    <div class="product-parts">
      <div class="details-box">
        <p class="label-md name">
          {{ getNickName() }}
        </p>
        <p class="sub-h-xs save-text" :class="{ selected: isSelected }" v-if="isDiscounted">
          SAVE {{ discountAmount * 100 }}%
        </p>
        <p class="p-xs price">
          <span :style="isDiscounted ? { 'text-decoration': 'line-through' } : {}">{{
            priceText
          }}</span
          >&nbsp;
          <span v-if="isDiscounted">{{ discountedPriceText }}</span>
        </p>
      </div>
      <div class="label-md per-day-box">
        <div>
          <span class="neutral-500-color discounted-price" v-if="isDiscounted"
            >${{ calculatePricePerDay(false) }}</span
          >
          &nbsp;
        </div>
        <div class="per-day-price neutral-500-color">
          <div>$</div>
          <div class="heading-1">{{ calculatePricePerDay(true).whole }}</div>
          <div>
            <p class="label-md">{{ calculatePricePerDay(true).fraction }}</p>
            <p class="label-xs">PER DAY</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.neutral-500-color {
  color: var(--neutrals-500);
}

.product-box {
  width: 100%;
  padding: 12px 16px;
  background: var(--primary-color, '#fff');
  border: 1px solid var(--primary-lighter);
  border-radius: 12px;
  position: relative;
  cursor: pointer;
}

.product-box.selected {
  border: 1px solid var(--accent-color, var(--primary-darker));
  background-color: var(--secondary-color, var(--primary-lighter));
}

.product-box.most-popular-box {
  padding-top: 32px;
}

.product-parts {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.details-box {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-direction: center;
  align-items: flex-start;
}

.per-day-box {
  display: flex;
}
.per-day-price {
  display: flex;
  text-align: left;
  gap: 4px;
}

.save-text {
  background-color: var(--accent-color, var(--primary-darker));
  color: var(--text-secondary, white);
  padding: 4px 6px;
  border-radius: 7px;
}

.selected .save-text {
  background: var(--primary-darker);
  color: #fff;
}

.selected .price,
.selected .name,
.selected .per-day-price {
  color: var(--text-dark-primary, var(--text-dark));
}

.most-popular {
  background: var(--accent-color, var(--primary-darker));
  color: var(--text-secondary, white);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-radius: 12px 12px 0px 0px;
}

.discounted-price {
  text-decoration: line-through;
  text-decoration-color: var(--danger-base);
  text-decoration-thickness: 2px;
}

</style>
