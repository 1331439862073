import { useRouter } from 'vue-router'

export const getPage = () => {
  const router = useRouter()
  if (router) {
    return router.currentRoute.value.name
  } else {
    return ''
  }
}

export const getLanguage = () => {
  return navigator.language.includes('tr') ? 'tr' : 'en'
}

export const getWhichStoreToRedirect = () => {
  const userAgent = navigator.userAgent || window.opera
  if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
    return 'app_store'
  } else {
    return 'play_store'
  }
}
