import { createRouter, createWebHistory } from 'vue-router'
import Settings from '../views/Settings.vue'
import VerifyEmail from '../views/VerifyEmail.vue'
import PaymentResult from '../views/PaymentResult.vue'
import Payment from '../views/Payment.vue'
import CheckoutPage from "../views/CheckoutPage.vue";
import OnboardingPage from "../views/OnboardingPage.vue";
import OnboardingTestPage from "../views/OnboardingTestPage.vue";
import { isVerificationRequired } from '@/helpers/verificationEmail'
import { useUserStore } from '@/store/user'
import { sendEvent } from '@/helpers/cerebro'
import { QUIZ_ID } from '@/constants/onboarding.js'

const routes = [
  { path: '/', redirect: { name: 'OnboardingPage' } },
  {
    path: '/onboarding',
    component: OnboardingPage,
    name: 'OnboardingPage',
    meta: { requiresAuth: false },
  },
  {
    path: '/verify',
    component: VerifyEmail,
    name: 'VerifyEmail',
    meta: { requiresAuth: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: { requiresAuth: true },
  },
  {
    path: "/checkout/:clientSecret/:subscription?/:customer?",
    component: CheckoutPage,
    name: "Checkout",
    meta: { onlyFree: true },
  },
  {
    path: '/payment-result',
    component: PaymentResult,
    name: 'PaymentResult',
    meta: { requiresAuth: true },
  },
  // {
  //   path: '/onboarding-test',
  //   component: OnboardingTestPage,
  //   name: 'OnboardingTestPage',
  //   meta: { requiresAuth: false },
  // },
  { path: '/:pathMatch(.*)*', redirect: { name: 'OnboardingPage' } },
]

const options = {
  redirectPageAfterLogin: { name: 'OnboardingPage' },
  redirectPageAfterLogout: { name: 'OnboardingPage' },
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  const currentUser = await userStore.getCurrentUser()

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (currentUser) {
      if (to.matched.some((record) => record.meta.onlyPro)) {
        if (userStore.isUserPro()) {
          next()
        } else {
          next(options.redirectPageAfterLogin)
        }
      } else {
        if (isVerificationRequired(currentUser) && to.name != 'VerifyEmail') {
          next({
            name: 'VerifyEmail',
          })
        } else if (!isVerificationRequired(currentUser) && to.name == 'VerifyEmail') {
          next(options.redirectPageAfterLogin)
        } else {
          next()
        }
      }
    } else {
      next(options.redirectPageAfterLogout)
    }
  } else if (to.matched.some((record) => record.meta.onlyUnauth)) {
    if (currentUser) {
      next(options.redirectPageAfterLogin)
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from, failure) => {
  window.scrollTo(0, 0)
  if (!failure)
    sendEvent({
      eventName: 'web_page_open',
      page: to.name,
      properties: {
        source: from.name || router.options.history.state.source || '',
        path: to.path,
        fullPath: to.fullPath,
        page_language: 'en',
        quiz_id: QUIZ_ID,
      },
    })
})

export default router
