<script setup>
import UserIcon from '@/assets/icons/user.svg'
import SparkleLogo from '@/components/atoms/SparkleLogo.vue'
import { useUserStore } from '@/store/user'
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
const userStore = useUserStore()

const isLoggedIn = computed(() => {
  const user = userStore.user
  if (user || localStorage.getItem('cdw_iuli')) return true
  else return false
})

const router = useRouter()
const route = useRoute()

const sparkleLogoClick = () => {
  if (route.name === 'OnboardingPage') {
    router.go(0)
  } else {
    router.push({ name: 'OnboardingPage' })
  }
}

const userIconClick = () => {
  if (route.name === 'Settings') {
    router.go(-1)
  } else {
    router.push({ name: 'Settings' })
  }
}
</script>

<template>
  <div class="header-container">
    <div class="header">
      <button @click="sparkleLogoClick">
        <SparkleLogo />
      </button>
      <div v-if="isLoggedIn" class="user-btn flex-center">
        <button @click="userIconClick">
          <UserIcon class="icon-24" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header-container {
  width: 100%;
  border-bottom: 1px solid var(--neutrals-200);
  padding: 8px 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  padding: 12px 16px;
}

.user-btn {
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--neutrals-200);
}

button {
  border: none;
  background-color: var(--background);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header-container {
    padding: 0;
  }
}
</style>
